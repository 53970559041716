import React, { useEffect, useState } from "react";
import ContentCreatorSignup from "./partnershipsSignup";
import getUsers from "../../helpers/getUsers";
import InfluencerCarousel from "../PartnersCarousel/partnersCarousel";
import { useLocation } from "react-router-dom";
import Spinner from "../../helpers/spinner";
import { Helmet } from "react-helmet";

const ContentCreatorPage = () => {
  const [showSignupForm, setShowSignupForm] = useState(false);
  const [users, setUsers] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const data = await getUsers();
      setUsers(data);
    };

    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const renderCreatorStep = (title, description) => (
    <div className="ccp-creator-step-card">
      <h3 className="ccp-creator-step-title">{title}</h3>
      <p className="ccp-creator-step-text">{description}</p>
    </div>
  );

  const renderMarketingSection = () => (
    <section className="ccp-marketing-section">
      <h2 className="ccp-section-title">Showcase Your Brand</h2>
      <h3 className="ccp-section-subtitle">
        Expand your audience and earn through affiliate programs
      </h3>
      <div className="ccp-creator-steps-container">
        {renderCreatorStep(
          "Step 1: Join Travel Affiliate Programs",
          "Sign up for affiliate programs with our partners: Booking.com, Aviasales.com, and GetYourGuide.com. Obtain your unique links from each platform. You'll need these to complete your NewTripIdeas profile and start earning."
        )}
        {renderCreatorStep(
          "Step 2: Create Your NewTripIdeas Profile",
          "Click 'Sign Up Now' below to create your profile. Enter your affiliate links, photos, and social media details. Your profile will appear in our affiliate carousel for 30 days, and your links will appear throughout the newtripideas.com website on the days you schedule."
        )}
        {renderCreatorStep(
          "Step 3: Grow Your Audience and Earn",
          "Screenshot your schedule to save it. On your scheduled days, your links are prominently displayed on the flight search and itinerary pages of the website. Direct your audience to newtripideas.com and you'll earn a percentage when they book through your links!"
        )}
      </div>
    </section>
  );

  // Main return with conditional rendering for signup form and influencer showcase
  return (
    <div className="content-creator-signup-wrapper">
      <Helmet>
        <title>Become a Travel Content Creator | New Trip Ideas</title>
        <meta
          name="description"
          content="Join NewTripIdeas' affiliate program and grow your audience by showcasing your brand. Earn through partnerships with Booking.com, Aviasales.com, and GetYourGuide.com."
        />
        <meta
          name="keywords"
          content="travel content creator, affiliate marketing, travel affiliate programs, grow audience, earn through travel"
        />
        <meta
          property="og:title"
          content="Showcase Your Brand as a Content Creator"
        />
        <meta
          property="og:description"
          content="Expand your audience and earn through travel affiliate programs. Sign up, create your NewTripIdeas profile, and display your affiliate links to start earning."
        />
        <meta property="og:image" content="URL_TO_CONTENT_CREATOR_IMAGE" />
        <meta
          property="og:url"
          content="https://newtripideas.com/partnerships"
        />
        <link rel="canonical" href="https://newtripideas.com/partnerships" />
      </Helmet>
      {renderMarketingSection()}

      {!showSignupForm && (
        <section className="ccp-signup-button-section">
          <button
            className="ccp-signup-button"
            onClick={() => setShowSignupForm(true)}
          >
            Sign Up Now!
          </button>
        </section>
      )}

      {/* Conditional rendering: Show signup form or influencer showcase */}
      {showSignupForm ? (
        <ContentCreatorSignup />
      ) : (
        <section style={{ paddingBottom: "50px" }}>
          {users.length > 0 ? (
            <InfluencerCarousel influencers={users} />
          ) : (
            <Spinner />
          )}
        </section>
      )}
    </div>
  );
};

export default ContentCreatorPage;
