import React, { useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import DOMPurify from "dompurify";
import openAiToken from "../../constants/apiKeys";
import "./travelQuestionaire.css";
import { useNavigate } from "react-router-dom";

const exploreQuestions = [
  {
    id: 1,
    question: "Where are you traveling from?",
    type: "text",
    placeholder: "Enter origin (City, State, Country)",
  },
  {
    id: 2,
    question: "How far are you willing to travel?",
    type: "select",
    options: [
      "Stay in my City",
      "Anywhere within 500 miles",
      "Stay in my Country",
      "Explore the world",
    ],
  },
  {
    id: 3,
    question: "What is your budget?",
    type: "select",
    options: [
      "Economical $",
      "Affordable $$",
      "Standard $$$ (most common)",
      "Premium $$$$",
      "Extravagant $$$$$",
    ],
  },
  {
    id: 4,
    question:
      "Select the things on this list you're most interested in (select multiple):",
    type: "multiselect",
    options: [
      "Hiking",
      "Beach Relaxation",
      "Historical Sites",
      "Food Exploration",
      "Adventure Sports",
      "Cultural Immersion",
      "Wildlife Viewing",
      "Photography",
      "Shopping",
      "Nightlife",
      "Wellness Activities",
      "Water Sports",
      "Art & Museums",
      "Local Festivals",
      "Nature Exploration",
    ],
  },
  {
    id: 5,
    question: "Any additional preferences or requirements? (Optional)",
    type: "textarea",
    placeholder:
      "E.g., accessibility needs, dietary restrictions, preferred season to travel, etc.",
  },
];

const destinationQuestions = [
  {
    id: 1,
    question: "What interests you the most?",
    type: "multiselect",
    options: [
      "Adventure",
      "Culture",
      "Food",
      "Nature",
      "Relaxation",
      "Shopping",
      "History",
      "Art",
      "Music",
      "Sports",
    ],
  },
  {
    id: 2,
    question: "What's your budget range?",
    type: "select",
    options: ["Any", "Budget-friendly", "Moderate", "Luxury"],
  },
  {
    id: 3,
    question: "Any specific requests?",
    type: "textarea",
    placeholder: "Tell us any specific preferences or requirements...",
  },
];

const TravelQuestionnaire = ({
  mode = "explore",
  destination = "",
  onSubmit,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Select question set based on mode
  const questions =
    mode === "explore" ? exploreQuestions : destinationQuestions;

  const handleResults = (results) => {
    navigate("/results", {
      state: {
        results,
      },
      replace: true,
    });
  };

  const handleTravelPrompt = async (answers, openAiToken, setLoading) => {
    const generatePrompt = (answers) => {
      // Get specific values from answers
      const origin = answers[1] || "Not specified";
      const travelDistance = answers[2] || "Not specified";
      const experienceType = answers[3] || "Not specified";
      const budget = answers[4] || "Not specified";
      const interests = Array.isArray(answers[5])
        ? answers[5].join(", ")
        : "Not specified";
      const additionalPreferences = answers[6] || "None specified";

      // Convert budget to a more specific range
      const budgetRanges = {
        "Economical $": "$0-$1,000",
        "Affordable $$": "$1,000-$3,000",
        "Standard $$$ (most common)": "$3,000-$6,000",
        "Premium $$$$": "$6,000-$10,000",
        "Extravagant $$$$$": "$10,000+",
      };

      const budgetRange = budgetRanges[budget] || budget;

      return `Create a personalized travel itinerary with exactly 6 destinations based on the following preferences:

        Origin Location: ${origin}
        Travel Range: ${travelDistance}
        Budget Level: ${budgetRange}
        Specific Interests: ${interests}
        Additional Requirements: ${additionalPreferences}

        For each destination, you must follow this EXACT format with no deviations. Each destination must be separated by exactly three dashes (---):

        # [Destination Name]
        [2-3 sentence description of why this matches their preferences and how it relates to their interests and experience type]

        Things to Do:
        1. [Activity 1 - must relate to their specified interests]
        2. [Activity 2 - must relate to their specified interests]
        3. [Activity 3 - must relate to their specified interests]
        4. [Activity 4 - must relate to their specified interests]

        Best Time to Visit: [Must specify exact months, e.g. "June to August" or "March to May"]
        Budget Range: [Must specify exact USD range, e.g. "$2,000-$3,500" or "$500-$1,000"]

        ---

        IMPORTANT FORMATTING RULES:
        1. You must include BOTH "Best Time to Visit:" and "Budget Range:" lines for each destination
        2. The budget range must be in USD and use a hyphen between numbers (e.g. "$2,000-$3,500")
        3. Best time to visit must specify actual months
        4. Each destination must have exactly 4 activities
        5. Do not skip lines between sections
        6. Do not add any additional sections or information
        7. Maintain consistent formatting across all 6 destinations
        8. Each destination must be separated by exactly three dashes (---)
        9. Activities must be numbered 1-4

        Example format:
        # Paris, France
        Perfect for cultural immersion and food exploration, Paris offers a blend of historic charm and modern luxury that matches your interests in art, cuisine, and photography.

        Things to Do:
        1. Explore the Louvre Museum's masterpieces
        2. Take a food photography workshop in Montmartre
        3. Join a French cooking class in Le Marais
        4. Photograph the city from the Eiffel Tower at sunset

        Best Time to Visit: April to June
        Budget Range: $3,000-$4,500

        ---

        Remember to:
        - Keep destinations within ${travelDistance} from ${origin}
        - Focus on ${experienceType.toLowerCase()} experiences
        - Include activities matching these interests: ${interests}
        - Keep suggested budgets within ${budgetRange} per destination
        - Consider these additional preferences: ${additionalPreferences}`;
    };

    const apiBody = {
      model: "gpt-3.5-turbo",
      messages: [{ role: "user", content: generatePrompt(answers) }],
      max_tokens: 4000,
    };

    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${openAiToken}`,
          },
          body: JSON.stringify(apiBody),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      const content = data.choices[0].message.content;

      // Parse the response into structured data
      const destinations = content
        .split("---")
        .filter(Boolean)
        .map((dest) => {
          const lines = dest.trim().split("\n").filter(Boolean);

          // Extract destination name
          const name = lines[0].replace("#", "").trim();

          // Extract description (everything between name and "Things to Do:")
          const descriptionLines = [];
          let i = 1;
          while (i < lines.length && !lines[i].includes("Things to Do:")) {
            descriptionLines.push(lines[i]);
            i++;
          }
          const description = descriptionLines.join(" ").trim();

          // Extract activities
          const activities = [];
          i++; // Skip "Things to Do:" line
          while (i < lines.length && activities.length < 4) {
            if (lines[i].match(/^\d\./)) {
              activities.push(lines[i].replace(/^\d\./, "").trim());
            }
            i++;
          }

          // Extract best time and budget - Updated parsing logic
          let bestTime = "";
          let budgetRange = "";

          // Look for these specific lines
          const remainingLines = lines.slice(i);
          remainingLines.forEach((line) => {
            if (line.startsWith("Best Time to Visit:")) {
              bestTime = line.replace("Best Time to Visit:", "").trim();
            } else if (line.startsWith("Budget Range:")) {
              budgetRange = line.replace("Budget Range:", "").trim();
            }
          });

          // Validate required fields
          if (!bestTime || !budgetRange) {
            console.warn(`Missing required fields for destination ${name}`);
            // Provide fallback values if missing
            bestTime = bestTime || "Contact destination for best times";
            budgetRange = budgetRange || "Varies by season";
          }

          return {
            name,
            description,
            activities,
            bestTime,
            budgetRange,
          };
        });

      // Validate that we have all required fields for each destination
      const validDestinations = destinations.filter(
        (dest) =>
          dest.name &&
          dest.description &&
          dest.activities.length === 4 &&
          dest.bestTime &&
          dest.budgetRange
      );

      if (validDestinations.length === 0) {
        throw new Error(
          "No valid destinations were generated. Please try again."
        );
      }

      // Sanitize the structured data
      const sanitizedDestinations = validDestinations.map((dest) => ({
        name: DOMPurify.sanitize(dest.name),
        description: DOMPurify.sanitize(dest.description),
        activities: dest.activities.map((activity) =>
          DOMPurify.sanitize(activity)
        ),
        bestTime: DOMPurify.sanitize(dest.bestTime),
        budgetRange: DOMPurify.sanitize(dest.budgetRange),
      }));

      handleResults(sanitizedDestinations);
    } catch (error) {
      console.error("There was an error:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleDestinationPrompt = async (answers, openAiToken, setLoading) => {
    const generatePrompt = (answers, destination) => {
      // Get specific values from answers
      const interests = Array.isArray(answers[1])
        ? answers[1].join(", ")
        : "Not specified";
      const budget = answers[2] || "Not specified";
      const specificRequests = answers[3] || "None specified";

      // Convert budget to a more specific range
      const budgetRanges = {
        Any: "$0-$10,000+",
        "Budget-friendly": "$0-$2,000",
        Moderate: "$2,000-$5,000",
        Luxury: "$5,000+",
      };

      const budgetRange = budgetRanges[budget] || budget;

      return `Create a detailed travel guide for ${destination} with exactly 6 things to do / places to visit based on the following preferences:

        Interests: ${interests}
        Budget Level: ${budgetRange}
        Special Requests: ${specificRequests}

        Please provide exactly 6 distinct areas or experiences in ${destination} following this EXACT format with no deviations. Each area must be separated by exactly three dashes (---):

        # [Area or Experience Name in ${destination}]
        [2-3 sentence description of why this matches their interests and preferences]

        Things to Do:
        1. [Activity 1 - must relate to their specified interests]
        2. [Activity 2 - must relate to their specified interests]
        3. [Activity 3 - must relate to their specified interests]
        4. [Activity 4 - must relate to their specified interests]

        Best Time to Visit: [Must specify exact months, e.g. "June to August" or "March to May"]
        Budget Range: [Must specify exact USD range for this experience, e.g. "$200-$500" or "$500-$1,000"]

        ---

        IMPORTANT FORMATTING RULES:
        1. You must include BOTH "Best Time to Visit:" and "Budget Range:" lines for each area
        2. The budget range must be in USD and use a hyphen between numbers (e.g. "$200-$500")
        3. Best time to visit must specify actual months
        4. Each area must have exactly 4 activities
        5. Do not skip lines between sections
        6. Do not add any additional sections or information
        7. Maintain consistent formatting across all 6 areas
        8. Each area must be separated by exactly three dashes (---)
        9. Activities must be numbered 1-4

        Example format:
        # Historic District, ${destination}
        Perfect for cultural immersion and photography enthusiasts, the Historic District offers a blend of architectural beauty and local culture that matches your interest in history and art.

        Things to Do:
        1. Take a guided walking tour of historic landmarks
        2. Visit the local history museum
        3. Photograph iconic architecture at sunset
        4. Join a local cooking class in a historic building

        Best Time to Visit: March to May
        Budget Range: $200-$400

        ---

        Remember to:
        - Focus on experiences matching these interests: ${interests}
        - Keep suggested activities within ${budgetRange} budget range
        - Consider these special requests: ${specificRequests}
        - Ensure all suggestions are actually available in ${destination}`;
    };

    const apiBody = {
      model: "gpt-3.5-turbo",
      messages: [
        { role: "user", content: generatePrompt(answers, destination) },
      ],
      max_tokens: 4000,
    };

    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${openAiToken}`,
          },
          body: JSON.stringify(apiBody),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      const content = data.choices[0].message.content;

      // Parse the response into structured data
      const destinations = content
        .split("---")
        .filter(Boolean)
        .map((dest) => {
          const lines = dest.trim().split("\n").filter(Boolean);

          // Extract name
          const name = lines[0].replace("#", "").trim();

          // Extract description
          const descriptionLines = [];
          let i = 1;
          while (i < lines.length && !lines[i].includes("Things to Do:")) {
            descriptionLines.push(lines[i]);
            i++;
          }
          const description = descriptionLines.join(" ").trim();

          // Extract activities
          const activities = [];
          i++; // Skip "Things to Do:" line
          while (i < lines.length && activities.length < 4) {
            if (lines[i].match(/^\d\./)) {
              activities.push(lines[i].replace(/^\d\./, "").trim());
            }
            i++;
          }

          // Extract best time and budget
          let bestTime = "";
          let budgetRange = "";

          const remainingLines = lines.slice(i);
          remainingLines.forEach((line) => {
            if (line.startsWith("Best Time to Visit:")) {
              bestTime = line.replace("Best Time to Visit:", "").trim();
            } else if (line.startsWith("Budget Range:")) {
              budgetRange = line.replace("Budget Range:", "").trim();
            }
          });

          // Validate required fields
          if (!bestTime || !budgetRange) {
            console.warn(`Missing required fields for area ${name}`);
            bestTime = bestTime || "Contact destination for best times";
            budgetRange = budgetRange || "Varies by season";
          }

          return {
            name,
            description,
            activities,
            bestTime,
            budgetRange,
          };
        });

      // Validate that we have all required fields for each destination
      const validDestinations = destinations.filter(
        (dest) =>
          dest.name &&
          dest.description &&
          dest.activities.length === 4 &&
          dest.bestTime &&
          dest.budgetRange
      );

      if (validDestinations.length === 0) {
        throw new Error("No valid areas were generated. Please try again.");
      }

      // Sanitize the structured data
      const sanitizedDestinations = validDestinations.map((dest) => ({
        name: DOMPurify.sanitize(dest.name),
        description: DOMPurify.sanitize(dest.description),
        activities: dest.activities.map((activity) =>
          DOMPurify.sanitize(activity)
        ),
        bestTime: DOMPurify.sanitize(dest.bestTime),
        budgetRange: DOMPurify.sanitize(dest.budgetRange),
      }));

      return sanitizedDestinations;
    } catch (error) {
      console.error("There was an error:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleNext = async () => {
    if (currentStep < questions.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      setLoading(true);
      try {
        let results;
        if (mode === "explore") {
          await handleTravelPrompt(answers, openAiToken, setLoading);
          // Remove results handling here as it's already done in handleTravelPrompt
        } else {
          results = await handleDestinationPrompt(
            answers,
            openAiToken,
            setLoading
          );
          handleResults(results); // Add this to handle destination mode results
        }
      } catch (error) {
        console.error("Error getting travel recommendations:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleInputChange = (value) => {
    setAnswers({
      ...answers,
      [questions[currentStep].id]: value,
    });
  };

  const handleMultiSelect = (option) => {
    const currentSelections = answers[questions[currentStep].id] || [];
    const newSelections = currentSelections.includes(option)
      ? currentSelections.filter((item) => item !== option)
      : [...currentSelections, option];

    setAnswers({
      ...answers,
      [questions[currentStep].id]: newSelections,
    });
  };

  const progress = ((currentStep + 1) / questions.length) * 100;

  return (
    <div className="questionnaire-wrapper">
      <div className="questionnaire-card">
        <div className="questionnaire-content">
          <div className="questionnaire-header">
            <h1>
              {mode === "explore"
                ? "Your Travel Style"
                : `Plan Your Trip to ${destination}`}
            </h1>
            <p>
              {mode === "explore"
                ? "Tell us what matters to you, and we'll come up with travel suggestions that fit your style."
                : "Let's personalize your experience with some quick questions."}
            </p>
          </div>

          <div className="progress-container">
            <div className="progress-steps">
              {[...Array(questions.length)].map((_, index) => (
                <div
                  key={index}
                  className={`progress-step ${
                    index <= currentStep ? "active" : ""
                  }`}
                >
                  {index + 1}
                </div>
              ))}
            </div>
            <div className="progress-bar">
              <div
                className="progress-bar-fill"
                style={{ width: `${progress}%` }}
              />
            </div>
          </div>

          <div className="question-container">
            <h2 className="question-title">
              {questions[currentStep].question}
            </h2>

            {questions[currentStep].type === "text" && (
              <input
                type="text"
                className="input-field"
                placeholder={questions[currentStep].placeholder}
                value={answers[questions[currentStep].id] || ""}
                onChange={(e) => handleInputChange(e.target.value)}
              />
            )}

            {questions[currentStep].type === "textarea" && (
              <textarea
                className="input-field min-h-[100px]"
                placeholder={questions[currentStep].placeholder}
                value={answers[questions[currentStep].id] || ""}
                onChange={(e) => handleInputChange(e.target.value)}
              />
            )}

            {questions[currentStep].type === "select" && (
              <div className="options-grid grid-cols-3">
                {questions[currentStep].options.map((option) => (
                  <button
                    key={option}
                    className={`option-button ${
                      answers[questions[currentStep].id] === option
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => handleInputChange(option)}
                  >
                    {option}
                  </button>
                ))}
              </div>
            )}

            {questions[currentStep].type === "multiselect" && (
              <div className="options-grid grid-cols-3">
                {questions[currentStep].options.map((option) => (
                  <button
                    key={option}
                    className={`option-button ${
                      (answers[questions[currentStep].id] || []).includes(
                        option
                      )
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => handleMultiSelect(option)}
                  >
                    {option}
                  </button>
                ))}
              </div>
            )}
          </div>

          <div className="navigation-buttons">
            <button
              onClick={handlePrevious}
              disabled={currentStep === 0}
              className={`nav-button previous ${
                currentStep === 0 ? "disabled" : ""
              }`}
            >
              <ChevronLeft className="icon" />
              <span>Previous</span>
            </button>
            <button
              onClick={handleNext}
              className="nav-button next"
              disabled={loading}
            >
              <span>
                {loading
                  ? "Generating Ideas..."
                  : currentStep === questions.length - 1
                  ? mode === "explore"
                    ? "Discover New Trip Ideas"
                    : "Get Personalized Suggestions"
                  : "Next"}
              </span>
              <ChevronRight className="icon" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravelQuestionnaire;
