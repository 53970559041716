// BlogEditor.jsx
import React, { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  updateDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { storage, db } from "../../database/db";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./blogEditor.css";

const BlogEditor = ({ onClose, editBlog }) => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [metaDescription, setMetaDescription] = useState("");
  const [keywords, setKeywords] = useState("");

  useEffect(() => {
    if (editBlog) {
      setTitle(editBlog.title);
      setContent(editBlog.content);
      setImageUrl(editBlog.imageUrl);
      setMetaDescription(editBlog.metaDescription || "");
      setKeywords(editBlog.keywords || "");
    }
  }, [editBlog]);

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let finalImageUrl = imageUrl;

      if (image) {
        // If editing and there's an existing image, delete it
        if (editBlog?.imageUrl) {
          const oldImageRef = ref(storage, editBlog.imageUrl);
          await deleteObject(oldImageRef);
        }

        // Upload new image
        const imageRef = ref(
          storage,
          `blog-images/${Date.now()}-${image.name}`
        );
        const snapshot = await uploadBytes(imageRef, image);
        finalImageUrl = await getDownloadURL(snapshot.ref);
      }

      const blogData = {
        title,
        content,
        imageUrl: finalImageUrl,
        metaDescription,
        keywords,
        updatedAt: serverTimestamp(),
      };

      if (editBlog) {
        // Update existing blog
        await updateDoc(doc(db, "blogs", editBlog.id), blogData);
      } else {
        // Create new blog
        blogData.createdAt = serverTimestamp();
        await addDoc(collection(db, "blogs"), blogData);
      }

      onClose();
    } catch (error) {
      console.error("Error saving blog post:", error);
    } finally {
      setLoading(false);
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
      ["link", "image"],
      ["clean"],
    ],
  };

  return (
    <div className="editor-modal">
      <div className="editor-container">
        <form onSubmit={handleSubmit} className="editor-form">
          <input
            type="text"
            placeholder="Blog Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="editor-input"
            required
          />

          <div className="seo-section">
            <input
              type="text"
              placeholder="Meta Description (for SEO)"
              value={metaDescription}
              onChange={(e) => setMetaDescription(e.target.value)}
              className="editor-input"
            />
            <input
              type="text"
              placeholder="Keywords (comma-separated)"
              value={keywords}
              onChange={(e) => setKeywords(e.target.value)}
              className="editor-input"
            />
          </div>

          <div
            className="image-upload"
            onClick={() => document.getElementById("image-input").click()}
          >
            {image || imageUrl ? "Image selected" : "Click to upload image"}
            <input
              id="image-input"
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>

          <ReactQuill
            value={content}
            onChange={setContent}
            modules={modules}
            className="editor-quill"
            theme="snow"
          />

          <div className="editor-buttons">
            <button
              type="button"
              onClick={onClose}
              className="editor-button cancel-button"
              disabled={loading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="editor-button submit-button"
              disabled={loading}
            >
              {loading
                ? "Saving..."
                : editBlog
                ? "Update Post"
                : "Publish Post"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BlogEditor;
