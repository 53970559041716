// Footer.jsx
import React from "react";
import { FaInstagram, FaTiktok } from "react-icons/fa";
import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-info">
            <p>© 2024 NEW TRIP IDEAS</p>
            <p>All rights reserved</p>
          </div>

          <div className="footer-contact">
            <p>cooper@tintechsolutions.com</p>
            <div className="social-icons">
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link-2"
              >
                <FaInstagram size={20} />
              </a>
              <a
                href="https://tiktok.com"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link-2"
              >
                <FaTiktok size={20} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
