import React, { useEffect, useMemo, useState, useRef } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { collection, addDoc, getDocs } from "firebase/firestore";
import { storage, db } from "../../database/db";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { ArrowLeft } from "lucide-react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripePaymentForm from "../../helpers/stripePayment";
// import InfluencerShowcase from "../PartnersCarousel/influencerShowcase";
import SuccessComponent from "./success";
import { BounceLoader } from "react-spinners";
import { Helmet } from "react-helmet";

// Prod
const stripePromise = loadStripe(
  "pk_live_51OxM3mIGI0E0PG2jz4rGRjyDThtxzGOnIED4XbgzsF5tWPlKyEaFN3uxRCx6XnVpNOwhhNmR4nHg2WK98ocUnE6M00cDjdjSft"
);

// Test
// const stripePromise = loadStripe(
//   "pk_test_51OxM3mIGI0E0PG2j5jfi9QzRteoVNFAznKUyFGfneMpf58cYhUQbc2WnNz7Ax868lAuthv59Jz7G4GCQUd4zUt1Y00lux9u1EC"
// );

const localizer = momentLocalizer(moment);

const ContentCreatorSignup = () => {
  const [formData, setFormData] = useState({
    instagramHandle: "",
    creatorName: "",
    tiktokHandle: "",
    bio: "",
    website: "",
    instagramLink: "",
    tiktokLink: "",
    facebookLink: "",
    xLink: "",
    profileImage: null,
    featuredImages: [],
    aviasalesLink: "",
    bookingLink: "",
    getyourguideLink: "",
    selectedDates: [],
  });

  const [totalCost, setTotalCost] = useState(3);
  const [progress, setProgress] = useState(0);
  const [occupiedDates, setOccupiedDates] = useState([]);
  const [showPayment, setShowPayment] = useState(false);
  const [profileImageTempUrl, setProfileImageTempUrl] = useState("");
  const [featuredImageTempUrls, setFeaturedImageTempUrls] = useState([]);
  const [fileData, setFileData] = useState({
    profileImage: null,
    featuredImages: [],
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isProfileOnly, setIsProfileOnly] = useState(false);

  const showcaseRef = useRef(null);

  useEffect(() => {
    fetchOccupiedDates();
  }, []);

  useEffect(() => {
    const displayDaysCost = isProfileOnly
      ? 0
      : formData.selectedDates.length * 3;
    setTotalCost(3 + displayDaysCost);
  }, [formData.selectedDates, isProfileOnly]);

  const fetchOccupiedDates = async () => {
    try {
      const usersRef = collection(db, "users");
      const querySnapshot = await getDocs(usersRef);
      let dates = [];
      querySnapshot.forEach((doc) => {
        const userData = doc.data();
        if (userData.selectedDates && Array.isArray(userData.selectedDates)) {
          const userDates = userData.selectedDates.map(
            (date) => new Date(date.seconds * 1000)
          );
          dates = [...dates, ...userDates];
        }
      });
      const uniqueDates = [...new Set(dates.map((date) => date.getTime()))].map(
        (time) => new Date(time)
      );
      setOccupiedDates(uniqueDates);
    } catch (error) {
      console.error("Error fetching occupied dates:", error);
    }
  };

  const isDateSelectable = (date) => {
    const today = moment().startOf("day");
    return moment(date).isAfter(today) && !isDateOccupied(date);
  };

  const handleDateSelect = (slotInfo) => {
    if (isProfileOnly) return;

    const selectedDate = moment(slotInfo.start).startOf("day").toDate();

    if (!isDateSelectable(selectedDate)) {
      return;
    }

    setFormData((prevState) => {
      const dateAlreadySelected = prevState.selectedDates.some((date) =>
        moment(date).isSame(selectedDate, "day")
      );

      if (dateAlreadySelected) {
        const newSelectedDates = prevState.selectedDates.filter(
          (date) => !moment(date).isSame(selectedDate, "day")
        );
        return { ...prevState, selectedDates: newSelectedDates };
      } else {
        return {
          ...prevState,
          selectedDates: [...prevState.selectedDates, selectedDate],
        };
      }
    });
  };

  const isDateOccupied = (date) => {
    return occupiedDates.some((occupiedDate) =>
      moment(occupiedDate).isSame(date, "day")
    );
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: event.title === "BOOKED" ? "#f0f0f0" : "#3174ad",
      color: event.title === "BOOKED" ? "black" : "white",
      border: "0px",
      display: "block",
    };
    return { style };
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageUpload = (e, type) => {
    const files = e.target.files;
    if (type === "profile") {
      const file = files[0];
      setFileData((prev) => ({ ...prev, profileImage: file }));
      setFormData((prev) => ({ ...prev, profileImage: file }));
      setProfileImageTempUrl(URL.createObjectURL(file));
    } else if (type === "featured") {
      const newFiles = Array.from(files);
      if (newFiles.length !== 3) {
        alert("Please select exactly 3 featured images.");
        setFileData((prev) => ({ ...prev, featuredImages: [] }));
        setFormData((prev) => ({ ...prev, featuredImages: [] }));
        return;
      }
      setFileData((prev) => ({ ...prev, featuredImages: newFiles }));
      setFormData((prev) => ({ ...prev, featuredImages: newFiles }));
      const newUrls = newFiles.map((file) => URL.createObjectURL(file));
      setFeaturedImageTempUrls(newUrls);
    }
  };

  const calendarEvents = useMemo(() => {
    return [
      ...occupiedDates.map((date) => ({
        start: new Date(date),
        end: new Date(date),
        title: "BOOKED",
      })),
      ...formData.selectedDates.map((date) => ({
        start: new Date(date),
        end: new Date(date),
        title: formData.creatorName,
      })),
    ];
  }, [occupiedDates, formData.selectedDates, formData.creatorName]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!formData.profileImage || formData.featuredImages.length !== 3) {
      alert("Please upload a profile image and exactly 3 featured images");
      setIsLoading(false);
      return;
    }

    if (!isProfileOnly && formData.selectedDates.length === 0) {
      alert(
        "Please select at least one date for display or choose profile-only option"
      );
      setIsLoading(false);
      return;
    }

    const cleanedFormData = {
      ...formData,
      instagramHandle: formData.instagramHandle.replace(/[@\s]/g, ""),
      tiktokHandle: formData.tiktokHandle.replace(/[@\s]/g, ""),
    };

    const requiredFields = ["creatorName", "instagramHandle", "instagramLink"];

    for (const field of requiredFields) {
      if (!cleanedFormData[field]) {
        alert(
          `Please fill in the ${field
            .replace(/([A-Z])/g, " $1")
            .toLowerCase()} field`
        );
        setIsLoading(false);
        return;
      }
    }

    setShowPayment(true);
    setIsLoading(false);

    setTimeout(() => {
      if (showcaseRef.current) {
        showcaseRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 100);
  };

  const handlePaymentSuccess = async () => {
    setIsLoading(true);
    try {
      const profileImageRef = ref(
        storage,
        `profileImages/${formData.profileImage.name}`
      );
      const profileImageTask = uploadBytesResumable(
        profileImageRef,
        formData.profileImage
      );

      let profileImageUrl = "";
      let featuredImageUrls = [];

      await new Promise((resolve, reject) => {
        profileImageTask.on(
          "state_changed",
          (snapshot) => {
            const progressPercent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgress(progressPercent);
          },
          (error) => {
            console.error("Error uploading profile image:", error);
            reject(error);
          },
          async () => {
            profileImageUrl = await getDownloadURL(
              profileImageTask.snapshot.ref
            );
            resolve();
          }
        );
      });

      featuredImageUrls = await Promise.all(
        formData.featuredImages.map(async (image) => {
          const imageRef = ref(storage, `featuredImages/${image.name}`);
          const uploadTask = uploadBytesResumable(imageRef, image);
          await uploadTask;
          return getDownloadURL(imageRef);
        })
      );

      await addDoc(collection(db, "users"), {
        creatorName: formData.creatorName,
        instagramHandle: formData.instagramHandle,
        tiktokHandle: formData.tiktokHandle,
        bio: formData.bio,
        website: formData.website,
        instagramLink: formData.instagramLink,
        tiktokLink: formData.tiktokLink,
        facebookLink: formData.facebookLink,
        xLink: formData.xLink,
        aviasalesLink: formData.aviasalesLink,
        bookingLink: formData.bookingLink,
        getyourguideLink: formData.getyourguideLink,
        selectedDates: formData.selectedDates,
        profileImage: profileImageUrl,
        featuredImages: featuredImageUrls,
        totalCost,
        isProfileOnly,
        createdAt: new Date(),
      });

      setIsSuccess(true);
      setTotalCost(0);
      setProgress(0);
      setShowPayment(false);
    } catch (error) {
      console.error("Error creating user:", error);
      alert("There was an error creating your profile. Please try again.");
    }
    setIsLoading(false);
  };

  const handleBackToForm = () => {
    if (fileData.profileImage) {
      const profileFile = new File(
        [fileData.profileImage],
        fileData.profileImage.name,
        { type: fileData.profileImage.type }
      );
      setFormData((prev) => ({ ...prev, profileImage: profileFile }));
    }

    const featuredFiles = fileData.featuredImages.map(
      (file) => new File([file], file.name, { type: file.type })
    );
    setFormData((prev) => ({ ...prev, featuredImages: featuredFiles }));

    setShowPayment(false);
  };

  if (isSuccess) {
    return (
      <SuccessComponent
        creatorName={formData.creatorName}
        selectedDates={formData.selectedDates}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>Become a Travel Content Creator | NewTripIdeas</title>
        <meta
          name="description"
          content="Join NewTripIdeas' affiliate program and grow your audience by showcasing your brand. Earn through partnerships with Booking.com, Aviasales.com, and GetYourGuide.com."
        />
        <meta
          name="keywords"
          content="travel content creator, affiliate marketing, travel affiliate programs, grow audience, earn through travel"
        />
        <meta
          property="og:title"
          content="Showcase Your Brand as a Content Creator"
        />
        <meta
          property="og:description"
          content="Expand your audience and earn through travel affiliate programs. Sign up, create your NewTripIdeas profile, and display your affiliate links to start earning."
        />
        <meta property="og:image" content="URL_TO_CONTENT_CREATOR_IMAGE" />
        <meta
          property="og:url"
          content="https://newtripideas.com/partnerships"
        />
        <link rel="canonical" href="https://newtripideas.com/partnerships" />
      </Helmet>
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
          }}
        >
          <BounceLoader color="#ffffff" size={60} />
        </div>
      )}
      {!showPayment ? (
        <div className="content-creator-signup">
          <h2>Sign Up as a Travel Agency or Content Creator</h2>

          <form onSubmit={handleSubmit}>
            <div className="form-section">
              <h3>Basic Information</h3>
              <input
                type="text"
                name="creatorName"
                value={formData.creatorName}
                onChange={handleInputChange}
                placeholder="Display Name"
                className="ccp-input ccp-input-with-prefix"
                required
              />
              <div className="ccp-input-group">
                <div className="ccp-input-prefix">@</div>
                <input
                  type="text"
                  name="instagramHandle"
                  value={formData.instagramHandle}
                  onChange={handleInputChange}
                  placeholder="Instagram Handle"
                  className="ccp-input ccp-input-with-prefix"
                  required
                />
              </div>

              <div className="ccp-input-group">
                <div className="ccp-input-prefix">@</div>
                <input
                  type="text"
                  name="tiktokHandle"
                  value={formData.tiktokHandle}
                  onChange={handleInputChange}
                  placeholder="TikTok Handle (optional)"
                  className="ccp-input ccp-input-with-prefix"
                />
              </div>

              <textarea
                name="bio"
                value={formData.bio}
                onChange={handleInputChange}
                placeholder="Short Introduction (up to 200 characters)"
                maxLength={200}
                required
                className="ccp-input ccp-input-with-prefix"
              />
            </div>

            <div className="form-section">
              <h3>Social Media Links</h3>
              <input
                type="url"
                name="instagramLink"
                value={formData.instagramLink}
                onChange={handleInputChange}
                placeholder="Instagram Profile URL"
                className="ccp-input ccp-input-with-prefix"
                required
              />
              <input
                type="url"
                name="website"
                value={formData.website}
                onChange={handleInputChange}
                placeholder="Website URL"
                className="ccp-input ccp-input-with-prefix"
              />
              <input
                type="url"
                name="tiktokLink"
                value={formData.tiktokLink}
                onChange={handleInputChange}
                placeholder="TikTok Profile URL (optional)"
                className="ccp-input ccp-input-with-prefix"
              />
              <input
                type="url"
                name="facebookLink"
                value={formData.facebookLink}
                onChange={handleInputChange}
                placeholder="Facebook Profile URL (optional)"
                className="ccp-input ccp-input-with-prefix"
              />
              <input
                type="url"
                name="xLink"
                value={formData.xLink}
                onChange={handleInputChange}
                placeholder="X (Twitter) Profile URL (optional)"
                className="ccp-input ccp-input-with-prefix"
              />
            </div>

            <div className="form-section">
              <h3>Profile Images</h3>
              <div className="file-input">
                <label htmlFor="profileImage">Profile Image:</label>
                <input
                  type="file"
                  id="profileImage"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e, "profile")}
                  required
                />
                {formData.profileImage && (
                  <p>Profile image selected: {formData.profileImage.name}</p>
                )}
              </div>
              <div className="file-input">
                <label htmlFor="featuredImages">
                  Featured Images (select 3):
                </label>
                <input
                  type="file"
                  id="featuredImages"
                  accept="image/*"
                  multiple
                  onChange={(e) => handleImageUpload(e, "featured")}
                  required
                />
                {formData.featuredImages.length > 0 && (
                  <p>{formData.featuredImages.length} of 3 images selected</p>
                )}
                {formData.featuredImages.map((file, index) => (
                  <p key={index}>{file.name}</p>
                ))}
              </div>
            </div>

            <div className="form-section">
              <h3>Display Options</h3>
              <div style={{ paddingBottom: "15px" }}>
                <input
                  type="checkbox"
                  id="profileOnly"
                  checked={isProfileOnly}
                  onChange={() => setIsProfileOnly(!isProfileOnly)}
                />
                <label htmlFor="profileOnly" style={{ paddingLeft: "10px" }}>
                  Profile Only ($3 for 30 days)
                </label>
              </div>
              {!isProfileOnly && (
                <>
                  <h3>Affiliate Links</h3>
                  <div style={{ fontSize: "14px", paddingBottom: "10px" }}>
                    Create a partner or affiliate profile on each of the
                    websites listed below to generate your links. These links
                    will be displayed across newtripideas.com on the days you
                    select below. The earnings from these programs will be
                    credited directly to your respective individual accounts,
                    not to newtripideas.com.
                  </div>

                  <ul className="affiliate-links">
                    <li>
                      <a
                        href="https://www.travelpayouts.com/en/offers/aviasales-affiliate-program/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Aviasales Affiliate Program
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.travelpayouts.com/en/offers/bookingcom-affiliate-program/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Booking.com Affiliate Program
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.travelpayouts.com/en/offers/getyourguide-affiliate-program/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        GetYourGuide Affiliate Program
                      </a>
                    </li>
                  </ul>

                  <div>
                    <span
                      style={{
                        color: "#919191",
                        fontSize: "12px",
                      }}
                    >
                      Ex. https://aviasales.tp.st/zGYVXqhz
                    </span>
                    <input
                      type="url"
                      name="aviasalesLink"
                      value={formData.aviasalesLink}
                      onChange={handleInputChange}
                      placeholder="Aviasales Affiliate Link"
                      className="ccp-input ccp-input-with-prefix"
                    />
                  </div>
                  <div>
                    <span
                      style={{
                        color: "#919191",
                        fontSize: "12px",
                      }}
                    >
                      Ex. https://www.booking.com/index.html?aid=8084301
                    </span>
                    <input
                      type="url"
                      name="bookingLink"
                      value={formData.bookingLink}
                      onChange={handleInputChange}
                      placeholder="Booking.com Affiliate Link"
                      className="ccp-input ccp-input-with-prefix"
                    />
                  </div>
                  <div>
                    <span
                      style={{
                        color: "#919191",
                        fontSize: "12px",
                      }}
                    >
                      Ex.
                      https://www.getyourguide.com?partner_id=M6ML54R&cmp=share_to_earn
                    </span>
                    <input
                      type="url"
                      name="getyourguideLink"
                      value={formData.getyourguideLink}
                      onChange={handleInputChange}
                      placeholder="GetYourGuide Affiliate Link"
                      className="ccp-input ccp-input-with-prefix"
                    />
                  </div>
                  <h4>Select Display Days</h4>
                  <Calendar
                    localizer={localizer}
                    events={calendarEvents}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 400 }}
                    selectable
                    onSelectSlot={handleDateSelect}
                    eventPropGetter={eventStyleGetter}
                    dayPropGetter={(date) => {
                      if (!isDateSelectable(date)) {
                        return {
                          className: "unavailable-date",
                          style: {
                            backgroundColor: "#f0f0f0",
                            cursor: "not-allowed",
                          },
                        };
                      }
                    }}
                  />
                </>
              )}
              <div className="total-cost">
                <p>
                  Total Cost: <span className="cost-amount">${totalCost}</span>
                  {isProfileOnly
                    ? " (Profile only for 30 days)"
                    : formData.selectedDates.length > 0
                    ? ` (${formData.selectedDates.length} days selected)`
                    : ""}
                </p>
                <small>$3 signup fee + $3 per day links are displayed</small>
              </div>
            </div>

            <button type="submit" className="submit-button">
              Proceed to Payment
            </button>

            {progress > 0 && <p>Uploading: {progress}%</p>}
          </form>
        </div>
      ) : (
        <Elements stripe={stripePromise}>
          <div
            ref={showcaseRef}
            style={{ maxWidth: "90%", margin: "0 auto", position: "relative" }}
          >
            {/* <InfluencerShowcase
              influencer={{
                instagramHandle: formData.instagramHandle,
                creatorName: formData.creatorName,
                tiktokHandle: formData.tiktokHandle,
                bio: formData.bio,
                website: formData.website,
                instagramLink: formData.instagramLink,
                tiktokLink: formData.tiktokLink,
                facebookLink: formData.facebookLink,
                xLink: formData.xLink,
                profileImage: profileImageTempUrl,
                featuredImages: featuredImageTempUrls,
              }}
            /> */}
          </div>
          <div className="content-creator-signup">
            <h2>Review and Payment</h2>
            <button onClick={handleBackToForm} className="payment-back-button">
              <ArrowLeft size={20} />
              Back to Form
            </button>
            <div className="review-info">
              <h3>Your Information</h3>
              {!isProfileOnly && (
                <>
                  <p>
                    <strong>Aviasales Link:</strong> {formData.aviasalesLink}
                  </p>
                  <p>
                    <strong>Booking.com Link:</strong> {formData.bookingLink}
                  </p>
                  <p>
                    <strong>GetYourGuide Link:</strong>{" "}
                    {formData.getyourguideLink}
                  </p>
                  <div>
                    <strong>Selected Dates:</strong>
                    <ul>
                      {formData.selectedDates.map((date, index) => (
                        <li key={index}>{moment(date).format("MM/DD/YYYY")}</li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
              {isProfileOnly && (
                <p>
                  <strong>Profile Only:</strong> Your profile will be displayed
                  for 30 days.
                </p>
              )}
            </div>
            <div className="review-notice">
              <h3>Important: Please Review Your Information</h3>
              <p>
                Before proceeding with payment, please review your profile
                preview above. Make sure everything is accurate and complete.
              </p>
              <p>
                <strong>Please note:</strong>
              </p>
              <ul>
                <li>
                  Once you complete the payment, you will not be able to change
                  your information.
                </li>
                <li>
                  No refunds will be issued after your signup is processed.
                </li>
                <li>
                  Your selected dates and all provided information will be
                  final.
                </li>
                <li>
                  If you run into any issues, please send inquiries to
                  <strong> cooper@tintechsolutions.com</strong>.
                </li>
              </ul>
              <p>
                If you need to make any changes, please use the 'Back to Form'
                button to return to the signup form.
              </p>
            </div>
            <StripePaymentForm
              amount={totalCost}
              onPaymentSuccess={handlePaymentSuccess}
            />
          </div>
        </Elements>
      )}
    </>
  );
};

export default ContentCreatorSignup;
