import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Copy, ArrowLeft } from "lucide-react";
import "./travelResults.css";
import { Helmet } from "react-helmet-async";

const TravelResultsPage = () => {
  const [showNotification, setShowNotification] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const results = location.state?.results || [];

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 2000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <div className="travel-results">
      <Helmet>
        <title>
          Discover New Trip Ideas with AI | New Adventures Await with
          NewTripIdeas.com
        </title>
        <meta
          name="description"
          content="Explore new trip ideas and get AI-powered travel inspiration for your next adventure. Discover exciting destinations, unique activities, and personalized travel plans with New Trip Ideas."
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="new trip ideas, AI travel ideas, adventure planning, travel inspiration, personalized travel, explore new destinations, New Trip Ideas"
        />
        <link rel="canonical" href="https://www.newtripideas.com/" />
      </Helmet>
      <div className="results-container">
        <button className="back-button" onClick={() => navigate("/")}>
          <ArrowLeft />
          Back to Search
        </button>

        <div className="results-header">
          <h1 className="results-title">Your Travel Recommendations</h1>
          <p className="results-subtitle">
            Here are your personalized travel destinations based on your
            preferences
          </p>
        </div>

        <div className="destinations-grid">
          {results.map((destination, index) => (
            <div key={index} className="destination-card">
              <div className="card-header">
                <h2 className="destination-name">{destination.name}</h2>
                <button
                  className="copy-button"
                  onClick={() =>
                    copyToClipboard(JSON.stringify(destination, null, 2), index)
                  }
                  aria-label="Copy destination details"
                >
                  <Copy className="copy-icon" />
                </button>
              </div>

              <div className="card-content">
                <p className="destination-description">
                  {destination.description}
                </p>

                <div className="activities-section">
                  <h3 className="activities-title">Things to Do</h3>
                  <ul className="activities-list">
                    {destination.activities.map((activity, actIndex) => (
                      <li key={actIndex} className="activity-item">
                        <span className="activity-bullet">•</span>
                        <span>{activity}</span>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="info-grid">
                  <div className="info-item">
                    <h4>Best Time to Visit</h4>
                    <p>{destination.bestTime}</p>
                  </div>
                  <div className="info-item">
                    <h4>Budget Range</h4>
                    <p>{destination.budgetRange}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {showNotification && (
        <div className="copy-notification">Copied to clipboard!</div>
      )}
    </div>
  );
};

export default TravelResultsPage;
