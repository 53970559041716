import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import LetsPlanTrip from "./letsPlanTrip";
import getUsers from "../../helpers/getUsers";
import Subscribe from "../Home/subscribe";

import { useEffect, useState } from "react";
import InfluencerCarousel from "../PartnersCarousel/partnersCarousel";
import Spinner from "../../helpers/spinner";

const DiscoverIdeasPage = () => {
  const [users, setUsers] = useState([]);
  const location = useLocation();
  const startQuestionnaire = location.state?.startQuestionnaire || false;

  useEffect(() => {
    const fetchUsers = async () => {
      const data = await getUsers();
      setUsers(data);
    };

    fetchUsers();
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          Discover New Trip Ideas with AI | New Adventures Await with
          NewTripIdeas.com
        </title>
        <meta
          name="description"
          content="Explore new trip ideas and get AI-powered travel inspiration for your next adventure. Discover exciting destinations, unique activities, and personalized travel plans with New Trip Ideas."
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="new trip ideas, AI travel ideas, adventure planning, travel inspiration, personalized travel, explore new destinations, New Trip Ideas"
        />
        <link rel="canonical" href="https://www.newtripideas.com/" />
      </Helmet>

      <main className="mainContent">
        <section className="w-full" style={{ paddingBottom: "20px" }}>
          <LetsPlanTrip initialShowQuestionnaire={startQuestionnaire} />
        </section>

        <section>
          {users.length > 0 ? (
            <InfluencerCarousel influencers={users} />
          ) : (
            <Spinner />
          )}
        </section>
        {/* <section>
          <Subscribe />
        </section> */}
      </main>
    </div>
  );
};

export default DiscoverIdeasPage;
