import React from "react";
import { Camera, DollarSign, Users } from "lucide-react";
import { useNavigate } from "react-router-dom";

const ShowcaseBrandSection = () => {
  const navigate = useNavigate();

  const cards = [
    {
      icon: Camera,
      title: "Featured Showcase",
      description:
        "Secure a prime spot in our featured profiles section to showcase your brand, photos, and social links, reaching a broad audience of potential followers and collaborators.",
      buttonText: "Reserve your spot",
    },
    {
      icon: DollarSign,
      title: "Monetization Links",
      description:
        "Monetize your influence with affiliate links seamlessly integrated across NewTripIdeas.com. Turn every visit into income by leveraging our platform's monetization opportunities.",
      buttonText: "Start earning",
    },
    {
      icon: Users,
      title: "Expand Your Reach",
      description:
        "Join our vibrant community of travel enthusiasts and site visitors. Take advantage of cross-promotion opportunities to expand your reach and grow your customer base.",
      buttonText: "Join our Network",
    },
  ];

  return (
    <div className="showcase-section">
      <div className="showcase-header">
        <h2>Showcase Your Brand</h2>
        <p>
          Get personalized recommendations from our AI to explore the best
          activities, sights, and hidden gems at your chosen destination.
        </p>
      </div>

      <div className="cards-container">
        {cards.map((card, index) => (
          <div key={index} className="card">
            <div className="icon-container">
              <card.icon size={24} />
            </div>
            <h3>{card.title}</h3>
            <p>{card.description}</p>
            <button onClick={() => navigate("/partnerships")}>
              {card.buttonText}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShowcaseBrandSection;
