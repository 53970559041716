import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navigation/navbar";
import Footer from "./components/Navigation/footer";
import HomePage from "./components/Home/homePage";
import ContentCreatorSignup from "./components/Partnerships/partnershipsPage";
import { UserLinksProvider } from "./helpers/userLinksProvider";
import DiscoverIdeasPage from "./components/DiscoverIdeas/discoverIdeasPage";
import TravelResultsPage from "./components/DiscoverIdeas/travelResults";
import BlogPage from "./components/Blog/blogPage";
import BlogPost from "./components/Blog/blogPost";

const App = () => {
  return (
    <UserLinksProvider>
      <Router>
        <div className="app-container">
          <Navbar />
          <div className="body-container">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/discover" element={<DiscoverIdeasPage />} />
              <Route path="/partnerships" element={<ContentCreatorSignup />} />
              <Route path="/results" element={<TravelResultsPage />} />
              <Route path="/blog" element={<BlogPage />} />
              <Route path="/blog/:blogId" element={<BlogPost />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </UserLinksProvider>
  );
};

export default App;
