import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../database/db";
import { Helmet } from "react-helmet";
import "./blogPost.css";

const BlogPost = () => {
  const { blogId } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      const docRef = doc(db, "blogs", blogId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setPost({
          id: docSnap.id,
          ...docSnap.data(),
        });
      }
    };

    fetchPost();
  }, [blogId]);

  if (!post) return <div>Loading...</div>;

  return (
    <article className="blog-post">
      <Helmet>
        <title>{post.title} | Your Website Name</title>
        <meta
          name="description"
          content={post.metaDescription || post.content.substring(0, 155)}
        />
        <meta property="og:title" content={post.title} />
        <meta
          property="og:description"
          content={post.metaDescription || post.content.substring(0, 155)}
        />
        <meta property="og:image" content={post.imageUrl} />
        <meta property="og:type" content="article" />
        <meta name="keywords" content={post.keywords} />
        <link
          rel="canonical"
          href={`https://yourwebsite.com/blog/${post.id}`}
        />
      </Helmet>

      <Link to="/blog" className="back-to-blogs">
        Back to Blogs
      </Link>

      <header className="post-header">
        <h1 className="post-title">{post.title}</h1>
        <p className="post-meta">
          {new Date(post.createdAt?.toDate()).toLocaleDateString(undefined, {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </p>
      </header>

      <div className="post-image-container">
        <img src={post.imageUrl} alt={post.title} className="post-image" />
      </div>

      <div
        className="post-content"
        dangerouslySetInnerHTML={{ __html: post.content }}
      />
    </article>
  );
};

export default BlogPost;
