import React, { useEffect } from "react";

import InfluencerTravelSection from "../Partnerships/partnershipsMarketing";
import getUsers from "../../helpers/getUsers";
import InfluencerCarousel from "../PartnersCarousel/partnersCarousel";
import Spinner from "../../helpers/spinner";
import DestinationShowcase from "./featuredDestination";
import { Helmet } from "react-helmet";
import Subscribe from "./subscribe";
// import DiscoverSection from "./discoverSection";

const HomePage = () => {
  const [users, setUsers] = React.useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const data = await getUsers();
      setUsers(data);
    };

    fetchUsers();
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          Discover New Trip Ideas with AI | New Adventures Await with
          NewTripIdeas.com
        </title>
        <meta
          name="description"
          content="Explore new trip ideas and get AI-powered travel inspiration for your next adventure. Discover exciting destinations, unique activities, and personalized travel plans with New Trip Ideas."
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="new trip ideas, AI travel ideas, adventure planning, travel inspiration, personalized travel, explore new destinations, New Trip Ideas"
        />
        <link rel="canonical" href="https://www.newtripideas.com/" />
      </Helmet>

      <main className="mainContent">
        <section className="w-full">
          <DestinationShowcase />
        </section>
        {/* <section>
          <DiscoverSection />
        </section> */}
        <section>
          {users.length > 0 ? (
            <InfluencerCarousel influencers={users} />
          ) : (
            <Spinner />
          )}
        </section>
        <section
          style={{ backgroundColor: "var(--navbar-light)", marginTop: "50px" }}
        >
          <InfluencerTravelSection />
        </section>
        {/* <section>
          <Subscribe />
        </section> */}
      </main>
    </div>
  );
};

export default HomePage;
