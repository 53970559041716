import React, { useState, useEffect } from "react";
import { Search } from "lucide-react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../database/db";
import { useNavigate } from "react-router-dom";
import TripQuestionnaire from "../DiscoverIdeas/travelQuestionaire";
import "./featuredDestination.css";

const FeaturedDestination = () => {
  const [currentDestination, setCurrentDestination] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);
  const [questionnaireMode, setQuestionnaireMode] = useState("explore");
  const navigate = useNavigate();

  const handleExploreClick = () => {
    navigate("/discover", { state: { startQuestionnaire: true } });
  };

  useEffect(() => {
    const fetchDestinations = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "destinations"));
        const destinationsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const randomIndex = Math.floor(Math.random() * destinationsData.length);
        setCurrentDestination(destinationsData[randomIndex]);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching destinations:", error);
        setLoading(false);
      }
    };

    fetchDestinations();
  }, []);

  const handleSearch = () => {
    if (searchInput.trim()) {
      setQuestionnaireMode("destination");
      setShowQuestionnaire(true);
    }
  };

  if (loading || !currentDestination) {
    return (
      <div className="featured-destination loading">
        <div className="loading-text">Loading destinations...</div>
      </div>
    );
  }

  return (
    <div
      className="featured-destination"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url(${currentDestination.imageUrl})`,
      }}
    >
      <div className="content-wrapper">
        {showQuestionnaire ? (
          <TripQuestionnaire
            mode={questionnaireMode}
            destination={searchInput}
            onSubmit={(results) => {
              navigate("/travel-results", {
                state: {
                  results,
                },
              });
            }}
          />
        ) : (
          <div className="content-inner">
            <h1 className="destination-title">{currentDestination.location}</h1>

            <div className="tags-container">
              {currentDestination.activities &&
                currentDestination.activities.map((activity, index) => (
                  <span key={index} className="tag">
                    {activity}
                  </span>
                ))}
            </div>

            <div className="search-container">
              <div className="search-box">
                <div className="search-top">
                  <div className="search-input-wrapper">
                    <Search className="search-icon" size={20} />
                    <input
                      type="text"
                      placeholder="Location, Address, or Destination"
                      className="search-input"
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") handleSearch();
                      }}
                    />
                  </div>
                  <button className="search-button" onClick={handleSearch}>
                    <svg
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="arrow-icon"
                    >
                      <path
                        fill="currentColor"
                        d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
                      />
                    </svg>
                  </button>
                </div>
                <div className="search-bottom">
                  <p className="search-subtitle">
                    Use AI to uncover the best things to do in your chosen
                    destination.
                  </p>
                </div>
              </div>
            </div>

            <div className="explore-link">
              <button onClick={handleExploreClick} className="explore-button">
                Explore NEW Travel Ideas →
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeaturedDestination;
