import React from "react";
import { Link } from "react-router-dom";
import "./blogPreview.css";

const BlogPreview = ({ blog }) => {
  const processContent = (html, maxLength) => {
    // Create temporary element
    const temp = document.createElement("div");
    temp.innerHTML = html;

    // Function to truncate nodes to target length
    const truncateNodes = (nodes, remaining) => {
      let result = "";
      for (let node of nodes) {
        if (remaining <= 0) break;

        if (node.nodeType === 3) {
          // Text node
          const text = node.textContent;
          if (text.length <= remaining) {
            result += text;
            remaining -= text.length;
          } else {
            result += text.substr(0, remaining) + "...";
            remaining = 0;
          }
        } else if (node.nodeType === 1) {
          // Element node
          // Only preserve basic formatting tags
          const tag = node.tagName.toLowerCase();
          if (["p", "br", "b", "strong", "i", "em"].includes(tag)) {
            const processedContent = truncateNodes(
              Array.from(node.childNodes),
              remaining
            );
            if (processedContent) {
              result += `<${tag}>${processedContent}</${tag}>`;
              // Approximate remaining length
              remaining -= processedContent.replace(/<[^>]+>/g, "").length;
            }
          } else {
            // For other tags, just process their content
            result += truncateNodes(Array.from(node.childNodes), remaining);
          }
        }
      }
      return result;
    };

    // Process and truncate content
    const processedContent = truncateNodes(
      Array.from(temp.childNodes),
      maxLength
    );
    return processedContent;
  };

  return (
    <Link to={`/blog/${blog.id}`} className="blog-preview">
      <img
        src={blog.imageUrl}
        alt={blog.title}
        className="preview-image"
        loading="lazy"
      />
      <div className="preview-content">
        <h2 className="preview-title">{blog.title}</h2>
        <div
          className="preview-excerpt"
          dangerouslySetInnerHTML={{
            __html: processContent(blog.content, 150),
          }}
        />
        <p className="preview-date">
          {new Date(blog.createdAt?.toDate()).toLocaleDateString()}
        </p>
      </div>
    </Link>
  );
};

export default BlogPreview;
