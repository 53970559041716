// NavBar.jsx
import React, { useState } from "react";
import { Menu, X } from "lucide-react";
import "./navbar.css";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="nav-container">
        {/* Desktop and Mobile Navigation Container */}
        <div className="nav-wrapper">
          {/* Desktop Navigation */}
          <div className="nav-links desktop">
            <a href="/" className="nav-link active">
              HOME
            </a>
            <a href="/discover" className="nav-link">
              DISCOVER
            </a>
            <a href="/partnerships" className="nav-link">
              PARTNERSHIPS
            </a>
            <a href="/blog" className="nav-link">
              BLOG
            </a>
          </div>

          {/* Mobile Menu Button */}
          <button onClick={toggleMenu} className="mobile-menu-btn">
            {isOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>

        {/* Mobile Navigation */}
        <div className={`nav-links mobile ${isOpen ? "open" : ""}`}>
          <a href="/" className="nav-link active">
            HOME
          </a>
          <a href="/discover" className="nav-link">
            DISCOVER
          </a>
          <a href="/partnerships" className="nav-link">
            PARTNERSHIPS
          </a>
          <a href="/blog" className="nav-link">
            BLOG
          </a>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
