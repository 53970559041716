// BlogPage.jsx
import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  orderBy,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import { db, storage } from "../../database/db";
import BlogPreview from "./blogPreview";
import BlogEditor from "./blogEditor";
import "./blogPage.css";

const BlogPage = () => {
  const [blogs, setBlogs] = useState([]);
  const [clickCount, setClickCount] = useState(0);
  const [showEditor, setShowEditor] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [editingBlog, setEditingBlog] = useState(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      const blogsQuery = query(
        collection(db, "blogs"),
        orderBy("createdAt", "desc")
      );
      const snapshot = await getDocs(blogsQuery);
      const blogData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBlogs(blogData);
    };

    fetchBlogs();
  }, [showEditor]); // Refresh when editor is closed

  const handleHeaderClick = () => {
    setClickCount((prev) => {
      if (prev + 1 === 5) {
        setIsAdmin(true);
        return 0;
      }
      return prev + 1;
    });
  };

  const handleDelete = async (blog) => {
    if (window.confirm("Are you sure you want to delete this blog post?")) {
      try {
        // Delete the blog document
        await deleteDoc(doc(db, "blogs", blog.id));

        // Delete the associated image if it exists
        if (blog.imageUrl) {
          const imageRef = ref(storage, blog.imageUrl);
          await deleteObject(imageRef);
        }

        // Update the local state
        setBlogs(blogs.filter((b) => b.id !== blog.id));
      } catch (error) {
        console.error("Error deleting blog:", error);
      }
    }
  };

  const handleEdit = (blog) => {
    setEditingBlog(blog);
    setShowEditor(true);
  };

  const handleCreateNew = () => {
    setEditingBlog(null);
    setShowEditor(true);
  };

  return (
    <div className="blog-container">
      <header className="blog-header" onClick={handleHeaderClick}>
        <h1 className="blog-title">Travel Stories & Insights</h1>
        <p className="blog-subtitle">
          Discover amazing destinations and travel experiences through our
          curated stories
        </p>
      </header>

      {isAdmin && (
        <div className="admin-panel">
          <button onClick={handleCreateNew} className="create-post-button">
            Create New Post
          </button>
        </div>
      )}

      <div className="blog-grid">
        {blogs.map((blog) => (
          <div key={blog.id} className="blog-preview-wrapper">
            <BlogPreview blog={blog} />
            {isAdmin && (
              <div className="admin-controls">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleEdit(blog);
                  }}
                  className="edit-button"
                >
                  Edit
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleDelete(blog);
                  }}
                  className="delete-button"
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        ))}
      </div>

      {showEditor && (
        <BlogEditor
          onClose={() => {
            setShowEditor(false);
            setEditingBlog(null);
          }}
          editBlog={editingBlog}
        />
      )}
    </div>
  );
};

export default BlogPage;
