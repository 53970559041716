import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { BounceLoader } from "react-spinners";

const cardElementStyle = {
  base: {
    fontSize: "16px",
    color: "#424770",
    "::placeholder": {
      color: "#aab7c4",
    },
  },
  invalid: {
    color: "#9e2146",
  },
};

const formStyle = {
  maxWidth: "500px",
  margin: "0 auto",
  padding: "20px",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  backgroundColor: "#ffffff",
};

const labelStyle = {
  display: "block",
  marginBottom: "10px",
  fontSize: "14px",
  fontWeight: "bold",
  color: "#32325d",
};

const buttonStyle = {
  backgroundColor: "#59a7c4",
  color: "#ffffff",
  border: "none",
  borderRadius: "4px",
  padding: "12px 16px",
  fontSize: "16px",
  fontWeight: "bold",
  cursor: "pointer",
  transition: "all 0.2s ease",
  width: "100%",
  marginTop: "20px",
};

const buttonHoverStyle = {
  backgroundColor: "#4a8ca5",
};

const errorStyle = {
  color: "#9e2146",
  fontSize: "14px",
  marginTop: "10px",
  padding: "10px",
  backgroundColor: "#ffebee",
  borderRadius: "4px",
};

const StripePaymentForm = ({ amount, onPaymentSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [buttonHovered, setButtonHovered] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    if (!stripe || !elements) {
      return;
    }

    try {
      // const domain = "http://127.0.0.1:5000";
      const domain =
        "https://shielded-journey-89926-a1d028117509.herokuapp.com";
      const response = await fetch(`${domain}/create-payment-intent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: amount * 100, // convert to cents
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to create payment intent");
      }

      const data = await response.json();

      const result = await stripe.confirmCardPayment(data.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      if (result.error) {
        setError(`Payment failed: ${result.error.message}`);
      } else {
        if (result.paymentIntent.status === "succeeded") {
          onPaymentSuccess();
        }
      }
    } catch (error) {
      setError(`An error occurred: ${error.message}`);
    }

    setProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit} style={formStyle}>
      <label style={labelStyle}>
        <h3>Card details</h3>
        <CardElement
          options={{
            style: cardElementStyle,
            hidePostalCode: true,
          }}
        />
      </label>
      {error && <div style={errorStyle}>{error}</div>}
      <button
        type="submit"
        disabled={!stripe || processing}
        style={{
          ...buttonStyle,
          ...(buttonHovered && !processing ? buttonHoverStyle : {}),
          opacity: !stripe || processing ? 0.7 : 1,
        }}
        onMouseEnter={() => setButtonHovered(true)}
        onMouseLeave={() => setButtonHovered(false)}
      >
        {processing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BounceLoader color="#ffffff" size={24} />
          </div>
        ) : (
          `Pay $${amount}`
        )}
      </button>
    </form>
  );
};

export default StripePaymentForm;
