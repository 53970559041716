import React, { useEffect, useState } from "react";

import { ChevronLeft, ChevronRight } from "lucide-react";
import { FaInstagram, FaFacebook, FaTiktok, FaHome } from "react-icons/fa";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const InfluencerCarousel = ({ influencers }) => {
  const [randomizedInfluencers, setRandomizedInfluencers] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const shuffled = [...influencers].sort(() => Math.random() - 0.5);
    setRandomizedInfluencers(shuffled);

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [influencers]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    nextArrow: <Arrow direction="next" />,
    prevArrow: <Arrow direction="prev" />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {randomizedInfluencers.map((influencer, index) => (
          <InfluencerShowcase key={index} influencer={influencer} />
        ))}
      </Slider>
    </div>
  );
};

const InfluencerShowcase = ({ influencer }) => {
  return (
    <div className="showcase-container">
      <div className="profile-section">
        <img
          src={influencer.profileImage}
          alt={influencer.creatorName}
          className="profile-image"
        />
        <div className="profile-info">
          <h2 className="creator-name">{influencer.creatorName}</h2>
          <div className="handles">
            {influencer.instagramHandle && (
              <span className="handle">
                Instagram: @{influencer.instagramHandle}
              </span>
            )}
            {influencer.tiktokHandle && (
              <span className="handle">TikTok: @{influencer.tiktokHandle}</span>
            )}
          </div>
          <div className="social-icons">
            {influencer.tiktokLink && (
              <a
                href={influencer.tiktokLink}
                className="social-link"
                target="_blank"
                rel="noreferrer"
              >
                <FaTiktok size={28} />
              </a>
            )}
            {influencer.instagramLink && (
              <a
                href={influencer.instagramLink}
                className="social-link"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram size={28} />
              </a>
            )}
            {influencer.facebookLink && (
              <a
                href={influencer.facebookLink}
                className="social-link"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebook size={28} />
              </a>
            )}
            {influencer.website && (
              <a
                href={influencer.website}
                className="social-link"
                target="_blank"
                rel="noreferrer"
              >
                <FaHome size={28} />
              </a>
            )}
          </div>
        </div>
      </div>

      <div className="content-section">
        <p className="bio">{influencer.bio}</p>
        <div className="image-grid">
          {influencer.featuredImages.slice(0, 3).map((image, index) => (
            <div key={index} className="image-container">
              <img
                src={image}
                alt={`Content ${index + 1}`}
                className="content-image"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InfluencerCarousel;

const Arrow = ({ direction, onClick }) => (
  <button onClick={onClick} className={`arrow-button ${direction}`}>
    {direction === "next" ? (
      <ChevronRight size={24} />
    ) : (
      <ChevronLeft size={24} />
    )}
  </button>
);
